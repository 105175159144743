.App {
  .btn {
    box-sizing: border-box;
    &.btn-primary {
      background-color: var(--color-navy-500);
      border: 1px solid var(--color-navy-500);
      color: white;
      font-weight: bold;
      border-radius: 6px;
      padding: 2px 8px;
      &.btn-outline {
        color: var(--color-navy-500);
        border: 1px solid var(--color-navy-500);
        background-color: white;
        
      }
    }
  }  
  .select {
    select {
      padding: 8px 32px 8px 14px;
    }
  }

  .MuiTextField-root {
    .MuiInputLabel-outlined {
      transform: translate(14px, 10px) scale(1); 
      &.Mui-focused, &.MuiFormLabel-filled {
        transform: translate(14px, -6px) scale(0.75);
      }
    }
    .MuiOutlinedInput-root {
      height: 35px;
      border-color: var(--color-navy-600);
      
    }
  }
}