@media print{
  @page{
    size: A4 landscape;
    page-break-before: auto;
  }

  
  html, body{
    height: 97%;
    page-break-after: avoid;
    page-break-before: avoid;
  }

  header {
    .title-wrap {
      color: rgba(0, 0, 0, 0.87);
      
      .title {
        font-size: 18px;
        font-weight: bold;
        color: rgba(0, 0, 0, 0.87);
        height: 32px;
        display: flex;
        align-items: center;
      }
    }
    .sub-title-header {
      margin-top: 10px;
      height: 24px;
    }
  }
  .not-print{
    display: none !important;
  }

  // 테이블 스타일 (프린트)
  table {
    box-sizing: border-box;
    height: 32px;
    width: 490px;
    .table-head {
      border-top: 2px solid var(--color-navy-500);
    }  
    td, th {
      padding: 0 0 0 10px;
      border-bottom: 1px solid #CCC;
      &.tc-125 {
        width: 125px;
      }
      &.no-padding {
        padding: 0;
      }
    }
    td {
      letter-spacing: -0.05em;
      height: 32px;
      font-size: 14px;
    }
    .th {
      font-weight: bold;
      color: rgba(51, 51, 51, 0.87);
    }
    .td {
      color: rgba(0, 0, 0, 0.87);
    }
  }
  section {
    // border: 1px solid orange;
    page-break-before: always;
    width: 1060px;
    height: 695px;
    padding: 30px;
    box-sizing: border-box;
    margin: calc((210mm - 608px - 100px) / 2) auto; 
  }
  .print-only {
    display: block;
    .sub-title-header {
      height: 24px;
      color: rgba(51, 51, 51, 0.87);
      font-size: 16px;
    }  
  }
}

//----print end-------

.FailedResult {
  margin-left: 40px;
  button {
    cursor: pointer;
  }
  letter-spacing: -0.05em;
  p, h3 {
    margin: 0;
  }
  // 단위 변환 버튼
  .unit-btns {
    position: absolute;
    right: 142px;
    top: 34px;
    display: flex;
    
    .btn-unit {
      width: 24px;
      height: 24px;
      font-size: 12px;
      font-weight: normal;
      display: flex;
      flex-direction:column;
      align-items: center;
      border: 1px solid #ccc !important;
      color: rgba(51, 51, 51, 0.87);

      &:first-child {
        border-radius: 6px 0 0 6px;
      }
      &:nth-child(2) {
        border-radius: 0 6px 6px 0;
      }
      &.active {
        background-color: var(--color-navy-600);
        color:  rgba(255, 255, 255, 0.87);
        &:hover {
          color:  rgba(255, 255, 255, 0.87);
        }
      }
      &:hover {
        color: rgba(51, 51, 51, 0.87);
      }
    
    } 
  }
  // 테이블 스타일
  table {
    box-sizing: border-box;
    height: 32px;
    width: 490px;
    tbody {
    
    }
    .table-head {
          border-top: 2px solid var(--color-navy-600);
    }
  
    td, th {
      padding: 0 0 0 10px;
      border-bottom: 1px solid #CCC;
      &.tc-125 {
        width: 125px;
      }
      &.no-padding {
        padding: 0;
      }
    }
    td {
      letter-spacing: -0.05em;
      height: 32px;
      font-size: 14px;
    }
    .th {
      font-weight: bold;
      color: rgba(51, 51, 51, 0.87);
    }
    .td {
      color: rgba(0, 0, 0, 0.87);
    }
  }
  .table-title {
    height: 32px;
    margin-top: 20px;
    display: flex;
    align-items: center;
    color: rgba(0, 0, 0, 0.87);
    font-weight: bold;
    font-size: 14px;
  }
  .title {
    margin-top: 24px;
    color: #E81C1C;
    font-size: 16px;
    font-weight: bold;
    line-height: 24px;
    height: 24px;
  }
  .causing {
    height: 32px;
    font-size: 14px;
    line-height: 32px;
    color: #E81C1C;
  }
}