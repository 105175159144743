body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
  
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --color-navy-100: #c5cae9;
  --color-navy-400: #5c6bc0;
  --color-navy-500: #3f51b5;
  --color-navy-600: #3949ab;
  --color-navy-700: #303f9f;
  --color-navy-900: #1a237e;

  --color-navy-600-alpha: rgba(57, 73, 171, 0.87);
}