.ProjectList {
  background-color: white;
  width: 1200px;

  height: 695px;  
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 30px 30px;
  letter-spacing: -0.05em;

  // 로딩 or 검색결과 표시
  .projectListMsg {
    position: absolute;
    top: 50%;
    padding-top: 43px;
    left: 50%;
    transform: translateX(-50%);

  }
  .title-wrap {    
    position: relative;
    height: 32px;
    width: 100%;
    display: flex;
    align-items: center;
    .title {
      color: rgba(0, 0, 0, 0.87);
      line-height: 1.5em;
      font-size: 18px;
      font-weight: bold;
      height: 24px;
    }
    .btn {
      position: absolute;
      right:0;
    }
  }
  .project-table {

    height: 473px;
    margin: 20px 5px auto 5px;
    display: flex;
    justify-content: center;
    th, td {
      padding: 0;
    }
    .MuiCheckbox-root {
      color: var(--color-navy-600);
    }
    .MuiTableCell-head {

      font-weight: 600;
      background: var(--color-navy-100);
      color: rgba(51, 51, 51, 0.87);
      box-sizing: border-box;
      font-size: 12px;

     // position: relative;
  
      .arrow-downward-icon {
        width: 10px;
        height: 10px;
        position: absolute;
        bottom: 7px;
        left: 50%;
        transform: translateX(-50%);
        color: #333;
      }
      &.td-address{
        text-overflow: ellipsis;

//        padding-left: 10px;
      } 

    }
    overflow-y: scroll;
    table {
      thead {
        tr {
          th {
            &:has(svg) {
              cursor: pointer;
            }
            .table-head-text {
              box-sizing: border-box;
              &.project-name {
              }
            }
      
          }
        }
      }
    }
    tbody {
      tr {
        td {
          font-size: 14px;
          box-sizing: border-box;
          
          border-bottom: 1px solid #CCCCCC;
          color: rgba(51, 51, 51, 0.87);
        }
      }
    }
    .MuiTableContainer-root  {
      width: auto;
      overflow-x: visible;
    }
    .table-body {
      .td-address{
        padding-left: 10px;              
        max-width: 450px;
        text-overflow:ellipsis;
        overflow:hidden;
        white-space:nowrap;
      }
      .tr:hover {
        background-color: var(--color-navy-400);
        .td {
        color: rgba(255, 255, 255, 0.87);
        }
      }
      
      .td {

        box-sizing: border-box;
        &.hightlight{
          font-weight: bold;
          color: var(--color-navy-600-alpha);
        }
        &.lightgrey {
          color: rgba(102, 102, 102, 0.87);
        }
        cursor: pointer;    
      }
      .status
      { 
        .status-wrapper {
          display: flex;
          flex-direction: row;
          justify-content:center;
          align-items: center;
          span {
            .MuiSvgIcon-root {
              width: 16px;
              color: #333;
            }
            line-height: 1.5em;

            display: flex;
            justify-self: center;
          }
        }
      .span{
        margin-left: 4px;
      }

      }
    }
  }
// 프로젝트명, 검색어, n개씩 보기
.head-wrap {
  box-sizing: border-box;
  margin-top: 30px;
 // padding: 4px;
  position: relative;
  width: 100%;
  .use-district {

    width: 185px;
    height: 32px;
    // select {
    //   padding: 4px auto 4px 0;
    // }
  }

  .search-form {

    
    margin-right: 10px;
    border-bottom: 1px solid #808080;
    
    .MuiSelect-select {
      option {
        // color :red;
        &:hover {
          color: blue !important;
        }
      }
      color:rgba(51, 51, 51, 0.87);

    }
    .MuiSelect-icon {
      color: #000;
    }
  }
  .address-input { 
    border-bottom: 1px solid #808080;
    .MuiInput-input {
      
    font-size: 16px;
    //color:rgba(153, 153, 153, 0.87);
  }
}
  .MuiInputBase-input {
  // .MuiOutlinedInput-input {
  //   height: 40px;
  //   padding: 0 8px;

   }
  .MuiSelect-select {
    padding: 4px auto 4px 0;


  }

  .view-num {
    .MuiSelect-icon {
      color: #000;
    }
    border-bottom: 1px solid #808080;
    position: absolute;
    right: 0;
    top: 0;
  }
}

  // 페이지네이션
  .pagination {
    z-index: 0;
    position: absolute;
    
    width: 514px;
    height: 30px;
    left: 50%;    
    transform : translateX(-50%);
    text-align: center;
    margin: 24px auto ; 
    .MuiPagination-root {
    display: flex;
      height: 30px;
      align-items: center;
      justify-content: center;
      .MuiPagination-ul {
        display: flex;
        .MuiPaginationItem-root {
          color: #333;
        } 
        .Mui-selected {
          color: #EEE;
        }

      }
    }
    .Mui-disabled {
      color: #999 !important;
      background-color: #EEE;
      opacity: 1;
      
    }
    

  }

  .btn-delete{
    width: 30px;
    height: 30px;
    border: 1px solid #CCC;
    border-radius: 6px;
    display: flex;
    justify-content: flex-start;
    cursor: pointer;
    justify-content: center;
    align-items: center;

    margin-left: 10px;
    margin-top: 24px;
    .MuiIconButton-label {
      color: #333;
      .MuiSvgIcon-root {
        display: flex;
        width: 16px;
        justify-content: center;
        align-items: center;
      }

    }
  }
}


.btn, .btn:hover {
  box-sizing: border-box;
  outline: none;
  &.btn-primary {
    background-color: var(--color-navy-600);
    border: 1px solid var(--color-navy-600);
    color: white;
    font-weight: bold;
    border-radius: 6px;
    padding: 2px 8px;

      &.btn-outline {
      color: var(--color-navy-600);
      border: 1px solid var(--color-navy-600);
      background-color: white;

      }
    }
  
    .btn-wrap {
      display: flex;

    }
}  