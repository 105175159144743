.MapContent {
  float: left;
  position: relative;
  .btn-groups-left {
    position: absolute;
    top: 20px;
    left: 20px;
  }
  .btn-groups-right {
    position: absolute;
    top: 20px;
    right: 20px;
  }
  .main-right-logo {
    position: absolute;
    bottom: 30px;
    right: 10px;
  }
}
.info_container {
  display: flex;
  position:relative;
  flex-direction: column;
  justify-content: flex-start;
  background-color: #fff;
  border: 1px solid #999;
  width: 80px;
  border-radius: 15px;
  z-index: 1000;
  .contents_price {
    display: flex;
    flex-direction: row;
    font-size: 13px;
    font-weight: 700;
    color: #cc0;
    // background-color: #fff;
    justify-content: center;      
  }
  .content_date {
    display: flex;
    flex-direction: row;
    font-size: 11px;
    font-weight: 700;
    color: #222;
    // background-color: #fff;
    justify-content: center;      
  }    
}
.info_container:after, .info_container:before {
  top: 100%;
	left: 50%;
	border: solid transparent;
  content:""; 
  height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;  
}

.info_container:after {
  border-color: rgba(255, 255, 255, 0);
	border-top-color: #fff;
	border-width: 5px;
	margin-left: -5px;
  // transform: rotate(180deg);
}

.info_container:before {
  border-color: rgba(226, 225, 245, 0);
	border-top-color: #555;
  border-width: 6px;
	margin-left: -6px;
}






.info_dark_container {
  display: flex;
  position:relative;
  flex-direction: column;
  justify-content: flex-start;
  background-color: #999;
  border: 1px solid #000;
  width: 70px;  
  border-radius: 15px;  
  .contents_price {
    display: flex;
    flex-direction: row;
    font-size: 13px;
    font-weight: 700;
    color: #fff;
    // background-color: #fff;
    justify-content: center;      
  }
  .content_date {
    display: flex;
    flex-direction: row;
    font-size: 11px;
    font-weight: 700;
    color: #222;
    // background-color: #fff;
    justify-content: center;      
  }    
}
.info_dark_container:after, .info_dark_container:before {
  top: 100%;
	left: 50%;
	border: solid transparent;
  content:""; 
  height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;  
}

.info_dark_container:after {
  border-color: rgba(255, 255, 255, 0);
	border-top-color: #999;
	border-width: 5px;
	margin-left: -5px;
  // transform: rotate(180deg);
}

.info_dark_container:before {
  border-color: rgba(226, 225, 245, 0);
	border-top-color: #000;
  border-width: 6px;
	margin-left: -6px;
}

.school_info {

  display: flex;
  position:relative;
  flex-direction: column;
  justify-content: center;  
  align-items: center;
  border: 2px solid #000;
  width: 120px;
  height: 60px;
  border-radius: 3px;
  z-index: 200;

 
  
}

.school_container {
  display: flex;
  position:relative;
  flex-direction: column;
  justify-content: center;  
  border: 1px solid #000;
  width: auto;
  height: auto;
  border-radius: 15px;
  background-color: #fff;
  z-index: 100;
  padding-left: 10px;
  padding-right: 10px;
 
  .content_name {
    display: flex;
    flex-direction: row;
    font-size: 11px;
    font-weight: 700;  
    color: #292929;
    text-overflow:ellipsis;
    white-space:nowrap;    
    justify-content: flex-start;  
    align-items: center;    
    width: auto;    
    height: 28px;
    .school_icon {      
      width: 16px;
      height: 16px;
      display: flex;    
      border: 1px solid #000;
      margin-top: 1px;
      border-radius: 13px;
      margin-right: 5px;
    }
  }
  .content_border_name {
    display: flex;
    flex-direction: row;
    font-size: 11px;
    font-weight: 700;  
    color: #292929;
    text-overflow:ellipsis;
    white-space:nowrap;    
    justify-content: flex-start;  
    align-items: center;    
    width: auto;
    border-bottom: 1px solid #999;    
    height: 28px;
   
    .school_icon {           
      width: 16px;
      height: 16px;
      display: flex;
      border: 1px solid #000;
      margin-top: 1px;
      border-radius: 13px;
      margin-right: 5px;
    }
  }
  .content_border_name:last-child{
    border-bottom: none;
  } 
  .content_category {
    display: flex;
    flex-direction: row;
    font-size: 11px;
    font-weight: 700;
    color: #292929;
    text-overflow:ellipsis;
    white-space:nowrap;
    align-items: center;    
    justify-content: center;      
    width: 110px;
    height: 28px;
  }
}

.school_container:after, .school_container:before {
  top: 14px;
  right: 100%;
	// border: solid transparent;
  content:""; 
  position: absolute;
	pointer-events: none;    
  // border-right: 5px solid;
  
  border-style: solid;
}

.school_container:after {
  border-color: rgba(255, 255, 255, 0);
	// border-right-color: #fff;
  margin-right: -1px;
	border-width: 5px;
	margin-top: -5px;  
  border-right: 5px solid #fff;
  
}

.school_container:before {
  border-color: rgba(226, 225, 245, 0);
  border-width: 5px;
	// margin-left: -6px;
  margin-top: -5px;
  border-right: 6px solid #000;
}

.hospital_container {
  display: flex;
  position:relative;
  flex-direction: column;
  justify-content: center;  
  border: 1px solid #0d8609;
  width: auto;
  height: auto;
  border-radius: 15px;
  background-color: #fff;
  z-index: 100;
  padding-left: 10px;
  padding-right: 10px;
 
  .content_name {
    display: flex;
    flex-direction: row;
    font-size: 11px;
    font-weight: 700;  
    color: #0d8609;
    text-overflow:ellipsis;
    white-space:nowrap;    
    justify-content: flex-start;  
    align-items: center;    
    width: auto;
    // border-bottom: 1px solid #999;    
    height: 28px;
    .hospital_icon {      
      width: 15px;
      height: 15px;
      display: flex;    
      margin-top: 1px; 
      margin-right: 5px;
    }
  }
  .content_border_name {
    display: flex;
    flex-direction: row;
    font-size: 11px;
    font-weight: 700;  
    color: #292929;
    text-overflow:ellipsis;
    white-space:nowrap;    
    justify-content: flex-start;  
    align-items: center;    
    width: auto;
    border-bottom: 1px solid #999;    
    height: 28px;
    margin-bottom: 2px;
    .hospital_icon {      
      width: 15px;
      height: 15px;
      display: flex;          
      margin-right: 5px;
    }
  }
  .content_border_name:last-child{
    border-bottom: none;
  } 
  .content_category {
    display: flex;
    flex-direction: row;
    font-size: 11px;
    font-weight: 700;
    color: #292929;
    text-overflow:ellipsis;
    white-space:nowrap;
    align-items: center;    
    justify-content: center;      
    width: 110px;
    height: 28px;
  }
}

.hospital_container:after, .hospital_container:before {
  top: 14px;
  right: 100%;
	// border: solid transparent;
  content:""; 
  position: absolute;
	pointer-events: none;    
  // border-right: 5px solid;
  
  border-style: solid;
}

.hospital_container:after {
  border-color: rgba(0, 255, 0, 0);
	// border-right-color: #fff;
  margin-right: -1px;
	border-width: 5px;
	margin-top: -5px;  
  border-right: 5px solid #fff;
}

.hospital_container:before {
  border-color: rgba(0, 225, 0, 0);
  border-width: 5px;
	// margin-left: -6px;
  margin-top: -5px;
  border-right: 6px solid #0d8609;
}
